import React from "react";
import { Box, Image, Paragraph, Heading, Link } from "theme-ui";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import { CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react'
import "../../styles/global.css";
import '@coreui/coreui/dist/css/coreui.min.css';

export default function HowToPlay() {
    const intl = useIntl();
    return (
        <CAccordionItem itemKey={1}>
            <CAccordionHeader>1 <FormattedMessage id="HOW_TO_PLAY" /></CAccordionHeader>
            <CAccordionBody>
                <CAccordion activeItemKey={1}>
                    <CAccordionItem itemKey={1}>
                        <CAccordionHeader>1.1 <FormattedMessage id="introduction" /></CAccordionHeader>
                        <CAccordionBody>
                            {
                                intl.locale === "en" &&
                                <>
                                    <Paragraph sx={styles.description}>
                                        World Super League aims to become a place where gamers,
                                        traders and fantasy football managers will gather to compete
                                        and try to get huge rewards! You can build your roster with the
                                        players you’ve always dreamed about and compete with users from all
                                        over the world, to climb the ranking and obtain the highest prizes!
                                    </Paragraph>

                                    <Heading as="h4">WSL - Assets</Heading>

                                    <Image alt="wsl assets" src="/img/how-to-play/banner_wsl_assets-min.png" className="full" />

                                    <Paragraph as="div" sx={styles.sectionContainer}>
                                        Players won’t be the only assets on WSL:
                                        <ul>
                                            <li>A coach is needed to deploy a line-up</li>
                                            <li>Modules are also needed to set up your formation</li>
                                            <li>Bonus cards are special items that have some specific conditions which,
                                                if met, will increase your total score</li>
                                        </ul>
                                    </Paragraph>

                                    <Heading as="h4">WSL - Marketplace</Heading>
                                    <Paragraph as="div" sx={styles.sectionContainer}>
                                    You can get new cards through packs and competitions, but also through the <Link target="_blank" href="https://marketplace.noku.io/" sx={styles.farmingClub}>Marketplace</Link>, where your trading skills will help you to improve your roster!
                                    </Paragraph>
                                </>
                            }

                            {
                                intl.locale === "it" &&
                                <>
                                    <Paragraph sx={styles.description}>
                                        World Super League è una piattaforma di gioco dove giocatori, 
                                        trader di carte e fanta-allenatori si riuniscono per competere e
                                        tentare di ottenere enormi ricompense!
                                        Puoi costruire la tua rosa con i calciatori che hai sempre sognato e
                                        sfidare utenti da ogni parte del mondo, per scalare la classifica e
                                        puntare ai premi più alti!
                                    </Paragraph>

                                    <Heading as="h4">WSL - Asset di Gioco</Heading>

                                    <Image alt="wsl assets" src="/img/how-to-play/banner_wsl_assets-min.png" className="full" />

                                    <Paragraph as="div" sx={styles.sectionContainer}>
                                        I calciatori non saranno le uniche carte disponibili in WSL:
                                        <ul>
                                            <li>È necessario almeno un allenatore per impostare la formazione</li>
                                            <li>I moduli sono necessari per stabilire i vari ruoli da schierare</li>
                                            <li>Le carte bonus sono oggetti speciali con specifiche condizioni che,
                                                se rispettate, aumentano il punteggio totale
                                            </li>
                                        </ul>
                                    </Paragraph>

                                    <Heading as="h4">WSL - Marketplace</Heading>
                                    <Paragraph as="div" sx={styles.sectionContainer}>
                                        Puoi ottenere nuove carte tramite i pacchetti e le competizioni, oppure attraverso il <Link target="_blank" href="https://marketplace.noku.io/" sx={styles.farmingClub}>Marketplace</Link>, dove le tue abilità di compravendita ti aiuteranno a migliorare la tua rosa!
                                    </Paragraph>
                                </>
                            }

                        </CAccordionBody>
                    </CAccordionItem>
                    <CAccordionItem itemKey={2}>
                        <CAccordionHeader>1.2 <FormattedMessage id="rules" /></CAccordionHeader>
                        <CAccordionBody>
                            <Paragraph sx={styles.description}>
                                {
                                    intl.locale === "en" && <>
                                        In World Super League you can join numerous competitions choosing the formation of your dream!<br /><br />
                                        In order to successfully deploy a formation you must select 22 items:
                                        <ul>
                                            <li>1 coach</li>
                                            <li>1 module</li>
                                            <li>11 starting players</li>
                                            <li>4 players on the bench</li>
                                            <li>5 bonus cards</li>
                                        </ul>
                                        
                                        <Image alt="formazione" src="/img/how-to-play/formazione-mock.png" className="full" />

                                        Each card mentioned before will contribute to increase your total score in different ways.<br /><br />
                                        Starting players will get a score based on their real performances during the matchday of that competition, on top of that they can get a bonus/malus for some specific events.<br />
                                        Here’s the list:
                                        <ul>
                                            <li>+ 3 for each goal scored</li>
                                            <li>+ 1 for each assist served</li>
                                            <li>- 0,5 and - 1 respectively for yellow and red cards</li>
                                            <li>- 3 for each penalty failed</li>
                                            <li>+ 3 for each penalty saved</li>
                                            <li>- 1 for each goal taken (from the goalkeeper)</li>
                                            <li>- 1 for each own goal scored</li>
                                        </ul>
                                        Players on the bench might replace the starting players in case one of them won’t get a score for any reason.<br />
                                        (You can find more details in 1.5 Substitutions).<br />
                                        <strong>You must deploy the lineups for every match, according to the countdown shown in the gaming platform.</strong><br />
                                        <br />
                                        The coach gives a +3 bonus to your total score in case the team shown on the card wins, no malus will be applied in case of draw/loss.<br /><br />
                                        The module determines which specific roles you can deploy in your lineup.<br />
                                        (You can find more details in 1.4 Modules)<br /><br />
                                        Bonus cards are really important in World Super League, you must choose 5 for each match and, if their specific condition is met, they will give a further bonus to your total score.<br />
                                        (You can find more details in 2.2 Extra Cards)<br /><br />
                                        A really important aspect of WSL rules: every card has a specific salary/weight. Players have salary and other cards have weight, the total salary/weight of your deployed lineup cannot exceed the salary cap of WSL, which is 400 for the Beta. <br />
                                        Leagues and cups will have a ranking based on the sum of all the scores obtained by the cards you deployed during the leagues/cups matchdays.<br />
                                        <strong>Note:</strong> bonus cards will trigger only for the starters. If your starting goalkeeper won’t play, then your backup won’t activate any bonus cards (such as Wall and Flying GK).
                                    </>
                                }

                                {
                                    intl.locale === "it" && <>
                                        In World Super League puoi partecipare a diverse competizioni scegliendo la formazione dei tuoi sogni!<br /><br />
                                        Per schierare con successo una formazione dovrai selezionare 22 item:
                                        <ul>
                                            <li>1 allenatore</li>
                                            <li>1 modulo</li>
                                            <li>11 giocatori titolari</li>
                                            <li>4 giocatori in panchina</li>
                                            <li>5 carte bonus</li>
                                        </ul>
                                        
                                        <Image alt="formazione" src="/img/how-to-play/formazione-mock.png" className="full" />

                                        Ognuna delle carte sopra indicate contribuirà a incrementare il tuo punteggio finale in diversi modi.<br /><br />
                                        I giocatori titolari ricevono un voto in base alle loro reali prestazioni durante il turno di gioco relativo alla competizione, in aggiunta possono fornire bonus/malus in caso di eventi specifici.<br />
                                        La lista dei bonus/malus standard prevede:
                                        <ul>
                                            <li>+ 3 per ogni rete realizzata</li>
                                            <li>+ 1 per ogni assist fornito</li>
                                            <li>- 0,5 e - 1 rispettivamente per ammonizioni ed espulsioni</li>
                                            <li>- 3 per ogni rigore fallito</li>
                                            <li>+ 3 per ogni rigore parato</li>
                                            <li>- 1 per ogni goal subito (dal portiere)</li>
                                            <li>- 1 per ogni autogol</li>
                                        </ul>
                                        I giocatori in panchina possono subentrare qualora il giocatore titolare non riceva un voto per qualsiasi motivo.<br />
                                        (Puoi approfondire l’argomento nella sezione 1.5 Sostituzioni).<br />
                                        <strong>Le formazioni vanno pubblicate di volta in volta, secondo il countdown indicato nella piattaforma di gioco.</strong><br />
                                        <br />
                                        L’allenatore fornisce un bonus di 3 punti al tuo punteggio totale se la squadra che allena vince durante quel turno. Non fornisce malus in caso di pareggio/sconfitta.<br /><br />
                                        Il modulo determina il tipo di ruoli che è possibile schierare nella tua formazione. <br />
                                        (Puoi approfondire l’argomento nella sezione 1.4 Moduli)<br /><br />
                                        Le carte bonus sono degli elementi molto importanti in World Super League, devi schierarne 5 per ogni turno e, se la condizione specifica della carta viene soddisfatta, forniscono un ulteriore bonus al punteggio totale.<br />
                                        (Puoi approfondire l’argomento nella sezione 2.2 Carte Extra)<br /><br />
                                        Un aspetto molto importante del regolamento riguarda il peso/salary.<br />
                                        Salary si riferisce ai giocatori e peso alle altre carte, la somma totale tra salary e peso delle carte che hai schierato in una formazione (compresi i giocatori in panchina) non potrà essere maggiore del salary cap, che per la Beta è stato impostato a 400.<br /><br />
                                        Leghe e coppe avranno una classifica basata sul totale dei punteggi ottenuti dalle carte che hai schierato nelle varie giornate.<br />
                                        <strong>Nota:</strong> le carte bonus si attivano solo per i titolari. Se il tuo portiere titolare non gioca e quindi subentra il portiere di riserva, non farà attivare eventuali carte bonus del portiere (come Wall e Flying GK).
                                    </>
                                }
                            </Paragraph>
                        </CAccordionBody>
                    </CAccordionItem>
                    <CAccordionItem itemKey={3}>
                        <CAccordionHeader>1.3 <FormattedMessage id="modules" /></CAccordionHeader>
                        <CAccordionBody>
                            <Paragraph sx={styles.description}>
                                {
                                    intl.locale === "en" && <>
                                        Another important aspect to consider is that the module card you choose will
                                        determine which specific roles you can deploy.<br /> 
                                        Different modules have different weights,
                                        see the list below.<br /><br />
                                        Weight:<br />
                                        20 = 343, 433, 442<br />
                                        15 = 3412, 3421, 4312, 4231<br />
                                        10 = 352, 3511, 4141, 4411
                                    </>
                                }

                                {
                                    intl.locale === "it" && <>
                                        Un altro aspetto importante da considerare è che il modulo scelto 
                                        per la formazione determina i ruoli specifici che possono essere 
                                        schierati in campo.<br /> 
                                        Ruoli diversi hanno pesi diversi, come puoi 
                                        vedere nella lista qui sotto.<br /><br />
                                        Peso:<br />
                                        20 = 343, 433, 442<br />
                                        15 = 3412, 3421, 4312, 4231<br />
                                        10 = 352, 3511, 4141, 4411
                                    </>
                                }
                            </Paragraph>
                        </CAccordionBody>
                    </CAccordionItem>
                    <CAccordionItem itemKey={4}>
                        <CAccordionHeader>1.4 <FormattedMessage id="roles" /></CAccordionHeader>
                        <CAccordionBody>
                            <Paragraph>
                                {
                                    intl.locale === "en" &&
                                    <>
                                        Before digging into the different modules, we should keep an eye on
                                        WSL roles, every player has at least one and maximum three roles. 
                                        This is the list of each role available on WSL:
                                        <br /><br />
                                        GK: Goalkeeper<br />
                                        CB: Center Back<br />
                                        RB: Right Back<br />
                                        LB: Left Back<br />
                                        WM: Wide Midfielder<br />
                                        CDM: Center Defensive Midfielder<br />
                                        CM: Center Midfielder<br />
                                        CAM: Center Attacking Midfielder<br />
                                        W: Wing<br />
                                        CF: Center Forward<br />
                                        ST: Striker<br /><br />

                                        As said before, every module will determine which specific roles you 
                                        can deploy, see the picture below: <br />
                                    </>
                                }

                                {
                                    intl.locale === "it" &&
                                    <>
                                        Prima di approfondire i vari moduli, è opportuno fare chiarezza sui 
                                        ruoli disponibili in WSL, ogni calciatore ha minimo un ruolo e al 
                                        massimo tre.<br /><br />

                                        GK: Portiere<br />
                                        CB: Difensore centrale<br />
                                        RB: Terzino destro<br />
                                        LB: Terzino sinistro<br />
                                        WM: Centrocampista di fascia<br />
                                        CDM: Mediano<br />
                                        CM: Centrocampista centrale<br />
                                        CAM: Trequartista<br />
                                        W: Ala<br />
                                        CF: Seconda punta<br />
                                        ST: Attaccante<br /><br />

                                        Come anticipato nel punto precedente, ogni modulo determinerà quali specifici ruoli 
                                        possono essere schierato, per maggiori dettagli fai riferimento alla 
                                        tabella qui sotto: <br/>
                                    </>
                                }
                            </Paragraph>
                            <Image alt="roles" src="/img/how-to-play/module.png" className="full" />
                        </CAccordionBody>
                    </CAccordionItem>
                    <CAccordionItem itemKey={5}>
                        <CAccordionHeader>1.5 <FormattedMessage id="substitutions" /></CAccordionHeader>
                        <CAccordionBody>
                            <Box>
                                <Paragraph>
                                    {
                                        intl.locale === "en" &&
                                        <>
                                            In case one or more of your <strong>starters</strong> won’t play that turn, or won’t get a score for any reasons, they will be replaced by picking the most suitable backup among those you deployed on the bench. The maximum number of allowed substitutions it’s the same of the players allowed on the bench (4).<br />
                                            <br />

                                            The Goalkeeper is a unique role, because he can only be replaced by another GK, and if you deploy more than one on the bench the system will automatically pick the first you did choose. <br />
                                            <br />
                                            For every other role the system will look into your bench trying to find a perfect substitute, so a player who has the very same role, and in case there’s no one available, it will try to replace him with another player depending on the module.
                                        </>
                                    }

                                    {
                                        intl.locale === "it" &&
                                        <>
                                            Nel caso in cui uno o più <strong>titolari</strong> durante un turno non dovessero giocare, né prendere il voto per qualsiasi motivo, verranno sostituiti prendendo la riserva più adatta tra quelle schierate in panchina. Il numero massimo di sostituzioni coincide con il numero di giocatori che puoi schierare in panchina (4).<br />
                                            Il portiere è un ruolo unico, perché ovviamente può essere sostituito solo da un altro portiere, e se ne viene schierato più di uno in panchina il sistema selezionerà automaticamente il primo schierato tra i due, in caso di sostituzione.<br />
                                            Per ogni altro ruolo il sistema tenterà una sostituzione <strong>perfetta</strong>, cioè con il medesimo ruolo, e se non ce ne sono disponibili, passerà ad una sostituzione <strong>adattata</strong>, a seconda del modulo.<br />
                                            In queste tabelle è possibile vedere, a seconda del modulo, quali sono le sostituzioni perfette (OK) e quelle adattate (che forniscono un malus di 1 punto). <br/>
                                        </>
                                    }

                                    {
                                        '12345678901'.split('').map((item, index) =>
                                            <img key={index} src={`/img/how-to-play/sub-${index + 1}.png`} className="full" alt="" />
                                        )
                                    }
                                </Paragraph>
                            </Box>
                        </CAccordionBody>
                    </CAccordionItem>
                    <CAccordionItem itemKey={6}>
                        <CAccordionHeader>1.6 <FormattedMessage id="marketplace" /></CAccordionHeader>
                        <CAccordionBody>
                            <Box>
                                <Paragraph>
                                    {
                                        intl.locale === "en" &&
                                        <>
                                            In our <a href="https://marketplace.noku.io/app/collection/world-super-league" target="_blank">Marketplace</a> &nbsp;
                                            you can buy and sell all the various game assets, through auctions and direct sales.<br />
                                            <br />
                                            You can trade cards using the two in-game currencies of World Super League: Noku and Credits.
                                            There's a minimum listing price for WSL cards, which depends on their type/overall, as shown in this tab: <br />
                                            <br />
                                            <Image alt="minimum listing" src="/img/how-to-play/minimun-listing-table.png" style={{ maxWidth: "400px", width: "100%" }} />
                                            <br />
                                            <br />
                                            <strong>Please note</strong>: the staff of World Super League strictly prohibits any kind of manipulation activities, being them about the market or the in-game currencies. Moving Noku or Credits from one account to another through suspicious operations might lead to a permanent ban.<br />
                                            <br />
                                            If you're struggling to sell a weak/common card you can also discard it and get some Credits back. 
                                            The amount of Credits will depend on the type/overall, as shown in this tab:<br />
                                            <br />
                                            <Image alt="minimum listing" src="/img/how-to-play/discard-table.png" style={{ maxWidth: "400px", width: "100%" }} />
                                            <br />
                                            <br />
                                            </>
                                    }

                                    {
                                        intl.locale === "it" &&
                                        <>
                                            Nel <a href="https://marketplace.noku.io/app/collection/world-super-league" target="_blank">Marketplace</a>
                                            &nbps; 
                                            puoi acquistare e vendere i vari asset di gioco, nelle modalità di asta e vendita diretta.<br />
                                            <br /> 
                                            La compravendita delle card avviene tramite le due valute di World Super League: 
                                            i Noku e i Credits. C'è un prezzo minimo per mettere in vendita le carte di WSL, che dipende dal loro tipo e overall, come mostrato nella tabella di seguito:<br />
                                            <br />
                                            <Image alt="minimum listing" src="/img/how-to-play/minimun-listing-table.png" style={{ maxWidth: "400px", width: "100%" }} />
                                            <br />
                                            <br />
                                            <strong>Nota bene</strong>: lo staff di World Super League vieta tassativamente ogni tipo di attività di manipolazione del mercato e delle due valute di gioco. Spostare Noku o Credits da un account a un altro tramite operazioni non congrue al reale prezzo di mercato può portare al ban dell’account.<br />
                                            <br />
                                            Se hai difficoltà nel vendere una carta debole o comune puoi scartarla e ricevere dei Crediti. La quantità di Crediti ottenuti dipenderà dal tipo e overall della carta, come mostrato nella tabella di seguito:<br />
                                            <br />
                                            <Image alt="minimum listing" src="/img/how-to-play/discard-table.png" style={{ maxWidth: "400px", width: "100%" }} />
                                        </>
                                    }                                    
                                </Paragraph>
                            </Box>
                        </CAccordionBody>
                    </CAccordionItem>
                </CAccordion>
            </CAccordionBody>
        </CAccordionItem>
    );
}

const styles = {
    farmingClub: {
        color: "#374253",
        textDecoration: "underline"
    },
    sectionContainer: {
        marginTop: 10,
    },
    description: {
        fontSize: 15
    },
    fullCard: {
        m: 20,
        width: "100%"
    }
}