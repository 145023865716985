import React from "react";
import { Box, Image, Heading } from "theme-ui";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import { CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react'
import "../../styles/global.css";
import '@coreui/coreui/dist/css/coreui.min.css';

export default function Packs() {
	const intl = useIntl();
	return (
		<CAccordionItem itemKey={4}>
			<CAccordionHeader>4 <FormattedMessage id="packs" /></CAccordionHeader>
			<CAccordionBody>
				<Box sx={{ mb: 20 }}>
					{intl.locale === "en" &&
						<>
							Other than prizes and marketplace, a good way to obtain new cards is
							the store, where you can find different kinds of packs.<br />
							For the open Beta there will be four different packs:<br />
							<br />
							<Heading as="h4">EXTRA CARDS PACK</Heading>
							5 Extra cards (1 module, 1 coach, 3 bonus)
							<Image className="packs" alt="EXTRA CARDS PACK" 
								src="/img/how-to-play/extra-min.png" />

							<Heading as="h4">SILVER PACK</Heading>
							6 Players (chances to find black and green cards)
							<Image className="packs" alt="SILVER PACK" src="/img/how-to-play/silver-min.png" />

							<Heading as="h4">GOLD PACK</Heading>
							6 Players (chances to find black and green cards)
							<Image className="packs" alt="GOLD PACK" src="/img/how-to-play/gold-min.png" />

							<Heading as="h4">BLACK PACK</Heading>
							12 Items, players + bonus (chances to find black and green cards)
							<Image className="packs" alt="BLACK PACK" src="/img/how-to-play/black-min.png" />

							<Heading as="h4">CHAMPIONS PACK</Heading>
							12 Items from the top European teams, players + bonus (chances to find black and green cards)
							<Image className="packs" alt="CHAMPIONS PACK" src="/img/how-to-play/champions-min.png" />

							<Heading as="h4">ALL-IN-ONE PACK</Heading>
							A full set of 26 cards with everything you need to play WSL
							<Image className="packs" alt="ALL-IN-ONE PACK" src="/img/how-to-play/all-in-one-green-min.png" />

							<Heading as="h4">ALL-IN-ONE PACK ENG</Heading>
							A full set of 26 cards (from the ENG-1 championship) with everything you need to play WSL
							<Image className="packs" alt="ALL-IN-ONE PACK ENG" src="/img/how-to-play/all-in-one-eng-min.png" />
			
							<Heading as="h4">ALL-IN-ONE PACK ITA</Heading>
							A full set of 26 cards (from the ITA-1 championship) with everything you need to play WSL
							<Image className="packs" alt="ALL-IN-ONE PACK ITA" src="/img/how-to-play/all-in-one-ita-min.png" />

							<Heading as="h4">ALL-IN-ONE PACK ESP</Heading>
							A full set of 26 cards (from the ESP-1 championship) with everything you need to play WSL
							<Image className="packs" alt="ALL-IN-ONE PACK ESP" src="/img/how-to-play/all-in-one-esp-min.png" />
						</>
					}

					{intl.locale === "it" &&
						<>
							Oltre ai Premi e al Marketplace, un buon modo per ottenere carte 
							nuove è lo Store, dove puoi trovare diversi tipi di pacchetti.<br />
							Per l’Open Beta ci saranno quattro tipi di pacchetti disponibili:<br />
							<br />
							<Heading as="h4">EXTRA CARDS PACK</Heading>
							5 Carte extra (1 modulo, 1 coach, 3 bonus)
							<Image className="packs" alt="EXTRA CARDS PACK" 
								src="/img/how-to-play/extra-min.png" />

							<Heading as="h4">SILVER PACK</Heading>
							6 Calciatori (possibilità di trovare carte nere e verdi)
							<Image className="packs" alt="SILVER PACK" src="/img/how-to-play/silver-min.png" />

							<Heading as="h4">GOLD PACK</Heading>
							6 Calciatori (possibilità di trovare carte nere e verdi)
							<Image className="packs" alt="GOLD PACK" src="/img/how-to-play/gold-min.png" />

							<Heading as="h4">BLACK PACK</Heading>
							12 Carte, calciatori + bonus (possibilità di trovare carte nere e verdi)
							<Image className="packs" alt="BLACK PACK" src="/img/how-to-play/black-min.png" />

							<Heading as="h4">CHAMPIONS PACK</Heading>
							Set completo di 26 carte con tutto ciò che ti serve per giocare a WSL
							<Image className="packs" alt="CHAMPIONS PACK" src="/img/how-to-play/champions-min.png" />

							<Heading as="h4">ALL-IN-ONE PACK</Heading>
							Set completo di 26 carte con tutto ciò che ti serve per giocare a WSL
							<Image className="packs" alt="ALL-IN-ONE PACK" src="/img/how-to-play/all-in-one-green-min.png" />

							<Heading as="h4">ALL-IN-ONE PACK ENG</Heading>
							Set completo di 26 carte (del campionato ENG-1) con tutto ciò che ti serve per giocare a WSL
							<Image className="packs" alt="ALL-IN-ONE PACK ENG" src="/img/how-to-play/all-in-one-eng-min.png" />
			
							<Heading as="h4">ALL-IN-ONE PACK ITA</Heading>
							Set completo di 26 carte (del campionato ITA-1) con tutto ciò che ti serve per giocare a WSL
							<Image className="packs" alt="ALL-IN-ONE PACK ITA" src="/img/how-to-play/all-in-one-ita-min.png" />

							<Heading as="h4">ALL-IN-ONE PACK ESP</Heading>
							Set completo di 26 carte (del campionato ESP-1)  con tutto ciò che ti serve per giocare a WSL
							<Image className="packs" alt="ALL-IN-ONE PACK ESP" src="/img/how-to-play/all-in-one-esp-min.png" />
						</>
					}
				</Box>
			</CAccordionBody>
		</CAccordionItem>
	);
}