import React from "react";
import { Box, Image, Paragraph, Heading } from "theme-ui";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import ExtraCards from './extraCards';
import { CAccordion, CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react'
import "../../styles/global.css";
import '@coreui/coreui/dist/css/coreui.min.css';

export default function WslCards() {
    const intl = useIntl();
    return (
      <CAccordionItem itemKey={2}>
        <CAccordionHeader>
          2 <FormattedMessage id="wsl_cards" />
        </CAccordionHeader>
        <CAccordionBody>
          <Paragraph sx={{ mb: 20 }}>
            {intl.locale === "en" && (
              <>
                Football is a team sport: you'll need various elements to get
                the results you wish for. The strength of your roster will
                depend on all the possible combinations you can do with the
                various types of cards you own.
              </>
            )}

            {intl.locale === "it" && (
              <>
                Il calcio è un gioco di squadra: avrai bisogno di vari elementi
                per ottenere i risultati che desideri. La forza della tua rosa
                dipende da tutte le combinazioni possibili che puoi schierare
                con i vari tipi di carte in tuo possesso.
              </>
            )}
          </Paragraph>
          <CAccordion activeItemKey={1}>
            <CAccordionItem itemKey={1}>
              <CAccordionHeader>
                2.1 <FormattedMessage id="player_cards" />
              </CAccordionHeader>
              <CAccordionBody>
                {intl.locale === "en" && (
                  <Box>
                    <Image
                      className="center"
                      alt="White cards"
                      src="/img/how-to-play/white_player_cards-min.png"
                    />
                    <Heading sx={styles.subtitle} as="h4">
                      White cards
                    </Heading>
                    <Paragraph sx={styles.description}>
                      White cards are similar to the gold ones, with the huge
                      difference that their use is limited: they will disappear
                      after being used!White cards are similar to the gold ones,
                      with the huge difference that their use is limited: they
                      will disappear after being used!
                    </Paragraph>

                    <Image
                      className="center"
                      alt="Gold cards"
                      src="/img/how-to-play/gold_player_cards-min.png"
                    />
                    <Heading sx={styles.subtitle} as="h4">
                      Gold cards
                    </Heading>
                    <Paragraph sx={styles.description}>
                      Standard cards for WSL: you will own them forever unlike
                      the white ones! Use your Gold cards to build your best
                      formation and compete in tournaments!
                    </Paragraph>

                    <Image
                      className="center"
                      alt="Black cards"
                      src="/img/how-to-play/black_player_cards-min.png"
                    />
                    <Heading sx={styles.subtitle} as="h4">
                      Black cards
                    </Heading>
                    <Paragraph sx={styles.description}>
                      The rarest cards of WSL: they can give a bonus and
                      deploying them has a lower impact on the formation's cap.
                    </Paragraph>

                    <Image
                      className="center"
                      alt="Green cards"
                      src="/img/how-to-play/green_player_cards-min.png"
                    />
                    <Heading sx={styles.subtitle} as="h4">
                      Green cards
                    </Heading>
                    <Paragraph sx={styles.description}>
                      Fusion cards: merge two combinable green cards to obtain a
                      SUPER CARD
                    </Paragraph>

                    <Image
                      className="center"
                      alt="Green cards"
                      src="/img/how-to-play/blue-min.png"
                    />
                    <Heading sx={styles.subtitle} as="h4">
                      Blue cards
                    </Heading>
                    <Paragraph sx={styles.description}>
                      These powerful cards have a higher overall and a lower
                      weight compared to the GOLD version. <br />The overall is
                      increased by 4 points, the weight is reduced by 50% for
                      goalkeepers, 40% for defenders and midfielders and 35% for
                      attackers.
                    </Paragraph>

                    <Image
                      className="center"
                      alt="Green cards"
                      src="/img/how-to-play/purple.png"
                    />
                    <Heading sx={styles.subtitle} as="h4">
                      Purple cards
                    </Heading>
                    <Paragraph sx={styles.description}>
                      These cards celebrate the best players of the season.<br />
                      Compared to the GOLD version, their Overall is increased by 5 points, the weight is reduced by 50% for goalkeepers and defenders, 40% for midfielders, and 35% for forwards.

                    </Paragraph>
                  </Box>
                )}

                {intl.locale === "it" && (
                  <Box>
                    <Image
                      className="center"
                      alt="White cards"
                      src="/img/how-to-play/white_player_cards-min.png"
                    />
                    <Heading sx={styles.subtitle} as="h4">
                      Carte bianche
                    </Heading>
                    <Paragraph sx={styles.description}>
                      Le carte bianche hanno le stesse caratteristiche delle
                      carte oro, con la grande differenza che il loro utilizzo è
                      limitato: possono infatti essere utilizzate una volta
                      sola!
                    </Paragraph>
                    <Image
                      className="center"
                      alt="Gold cards"
                      src="/img/how-to-play/gold_player_cards-min.png"
                    />
                    <Heading sx={styles.subtitle} as="h4">
                      Carte oro
                    </Heading>
                    <Paragraph sx={styles.description}>
                      Carte Standard di WSL: Saranno per sempre tue a differenza
                      delle carte bianche. Usa le tue carte oro per creare il
                      miglior team e competere nelle Leghe!
                    </Paragraph>

                    <Image
                      className="center"
                      alt="Black cards"
                      src="/img/how-to-play/black_player_cards-min.png"
                    />
                    <Heading sx={styles.subtitle} as="h4">
                      Carte nere
                    </Heading>
                    <Paragraph sx={styles.description}>
                      Le Carte più rare di WSL: possono dare dei bonus e il loro
                      peso avrà un impatto inferiore sul peso complessivo della
                      formazione.
                    </Paragraph>

                    <Image
                      className="center"
                      alt="Green cards"
                      src="/img/how-to-play/green_player_cards-min.png"
                    />
                    <Heading sx={styles.subtitle} as="h4">
                      Carte verdi
                    </Heading>
                    <Paragraph sx={styles.description}>
                      Carte Fusione: combina due carte verdi per ottenere una
                      carta SUPER.
                    </Paragraph>

                    <Image
                      className="center"
                      alt="Blue cards"
                      src="/img/how-to-play/blue-min.png"
                    />
                    <Heading sx={styles.subtitle} as="h4">
                      Carte blue
                    </Heading>
                    <Paragraph sx={styles.description}>
                      Queste carte davvero potenti hanno un overall più alto e
                      un peso inferiore rispetto alla versione ORO. <br />
                      L'overall è maggiorato di 4 punti, il peso viene ridotto
                      del 50% per i portieri, 40% per difensori e centrocampisti
                      e del 35% per gli attaccanti.
                    </Paragraph>

                    <Image
                      className="center"
                      alt="Green cards"
                      src="/img/how-to-play/purple.png"
                    />
                    <Heading sx={styles.subtitle} as="h4">
                      Carte viola
                    </Heading>
                    <Paragraph sx={styles.description}>
                      Queste carte celebrano i migliori giocatori della stagione.<br />
                      Rispetto alla versione ORO il loro Overall è maggiorato di 5 punti, il peso viene ridotto del 50% per i portieri e difensori, del 40% per i centrocampisti e del 35% per gli attaccanti.

                    </Paragraph>
                  </Box>
                )}
              </CAccordionBody>
            </CAccordionItem>
            <CAccordionItem itemKey={2}>
              <CAccordionHeader>
                2.2 <FormattedMessage id="extra_cards" />
              </CAccordionHeader>
              <CAccordionBody>
                <ExtraCards />
              </CAccordionBody>
            </CAccordionItem>
          </CAccordion>
        </CAccordionBody>
      </CAccordionItem>
    );
}

const styles = {
    sectionContainer: {
        marginTop: 10,
    },
    description: {
        fontSize: 15
    },
    fullCard: {
        m: 20,
        width: "100%"
    },
    subtitle: {
        mt: "0 !important"
    }
}