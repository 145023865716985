import React from "react";
import { Box, Text, Image, Heading, Paragraph } from "theme-ui";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import "../../styles/global.css";
import data from "../../cms/content/extraCards.json";
import prolificDefence from "../../assets/extraCards/prolific_defence-min.png";
import specialist from "../../assets/extraCards/specialist2-min.png";
import target from "../../assets/extraCards/target2-min.png";
import blessed from "../../assets/extraCards/blessed2-min.png";
import wall from "../../assets/extraCards/wall2-min.png";
import airDefence from "../../assets/extraCards/air-defence-min.png";
import neatDefence from "../../assets/extraCards/neat_defence-min.png";
import eagleEye from "../../assets/extraCards/eagle_eye-min.png";
import focus from "../../assets/extraCards/focus2-min.png";
import supersonic from "../../assets/extraCards/Supersonic-min.png";
import foresee from "../../assets/extraCards/foresee2-min.png";
import prolificTeam from "../../assets/extraCards/prolific_team-min.png";
import generousTeam from "../../assets/extraCards/generous_team-min.png";
import chemistry from "../../assets/extraCards/chemistry-min.png";
import patriotism from "../../assets/extraCards/patriotism-min.png";
import chemistrySpecific from "../../assets/extraCards/chemistry_bay-min.png";
import patriotismSpecific from "../../assets/extraCards/patriotism_eng-min.png";
import fromTheBench from "../../assets/extraCards/from_the_bench-min.png";
import moduli from "../../assets/extraCards/moduli-min.png";
import coach from "../../assets/extraCards/coach-min.png";

const performances = { prolificDefence, specialist, target, blessed, wall, airDefence, neatDefence, eagleEye, focus, supersonic, foresee, prolificTeam, generousTeam };
const stats = { chemistry, patriotism, chemistrySpecific, patriotismSpecific };
const extra = { fromTheBench, moduli, coach };

export default function ExtraCards() {
	const intl = useIntl();

	return (
	<Box className="extra-cards">
		{
			intl.locale === "en" &&
				<Box>
					<Heading sx={styles.bonusCards} as="h4">EXTRA CARDS</Heading>
					<Paragraph sx={styles.description}>
						Other than classic bonus/malus outlined in the 1.2 section, in World Super League it’s possible to add 5 extra bonus cards to each formation to improve your game experience.<br />
						Choose your Bonus cards wisely according to your formation, triggering them you might obtain enough points to climb the ladder!                                  
					</Paragraph>
				</Box>
		}

		{
			intl.locale === "it" &&
				<Box>
					<Heading sx={styles.bonusCards} as="h4">CARTE EXTRA</Heading>
					<Paragraph sx={styles.description}>
						Oltre ai classici bonus/malus descritti nella sezione 1.2 in World Super League puoi scegliere 5 bonus extra da schierare nella tua formazione, per arricchire e personalizzare la tua esperienza di gioco.<br />
						Scegli con cura i Bonus extra in base alla tua formazione, attivandoli potresti ottenere punti a sufficienza per scalare la classifica!                                    
					</Paragraph>
				</Box>
		}

		<Heading as="h4" sx={styles.bonusCards}>PERFORMANCE BONUSES</Heading>

		{data.performance.map((item, index) => (
			<Box as="div" key={index} sx={styles.paragraph}>
				<Image alt="card" src={performances[item.image]} sx={styles.cardImage} />
				<Box as="div" sx={styles.rightCol}>
					<Text as="h3" sx={styles.cardName}>
						{item.title}
					</Text>
					{item.weight &&
						<Text as="div" sx={styles.cardDescr}><FormattedMessage id="weight" />:</Text>
					}
					{item.weight &&
						<Text sx={styles.cardBody}>
							{item.weight}
						</Text>
					}
					<Text as="div" sx={styles.cardDescr}><FormattedMessage id="condition" />:</Text>
					<Text sx={styles.cardBody}>
						{item[intl.locale].condition}
					</Text>
					<Text as="div" sx={styles.cardDescr}>Bonus:</Text>
					<Text sx={styles.cardBody}>
						{item[intl.locale].bonus}
					</Text>
				</Box>
			</Box>
		))}

		<Heading as="h4" sx={styles.bonusCardsMarginTop}>STATS BONUSES</Heading>

		{data.stats.map((item, index) => (
			<Box as="div" key={index} sx={styles.paragraph}>
				<Image alt="card" src={stats[item.image]} sx={styles.cardImage} />
				<Box as="div" sx={styles.rightCol}>
					<Text as="h3" sx={styles.cardName}>
						{item.title}
					</Text>
					{item.weight &&
						<Text as="div" sx={styles.cardDescr}><FormattedMessage id="weight" />:</Text>
					}
					{item.weight &&
						<Text sx={styles.cardBody}>
							{item.weight}
						</Text>
					}
					<Text as="div" sx={styles.cardDescr}><FormattedMessage id="condition" />:</Text>
					<Text sx={styles.cardBody}>
						{item[intl.locale].condition}
					</Text>
					<Text as="div" sx={styles.cardDescr}>Bonus:</Text>
					<Text sx={styles.cardBody}>
						{item[intl.locale].bonus}
					</Text>
				</Box>
			</Box>
		))}

		<Heading as="h4" sx={styles.bonusCardsMarginTop}>EXTRA BONUSES</Heading>

		{data.extra.map((item, index) => (
			<Box as="div" key={index} sx={styles.paragraph}>
				<Image alt="card" src={extra[item.image]} sx={styles.cardImage} />
				<Box as="div" sx={styles.rightCol}>
					<Text as="h3" sx={styles.cardName}>
						{item.title}
					</Text>
					{item.weight &&
						<Text as="div" sx={styles.cardDescr}><FormattedMessage id="weight" />:</Text>
					}
					{item.weight &&
						<Text sx={styles.cardBody}>
							{item.weight}
						</Text>
					}
					<Text as="div" sx={styles.cardDescr}><FormattedMessage id="condition" />:</Text>
					<Text sx={styles.cardBody}>
						{item[intl.locale].condition}
					</Text>
					<Text as="div" sx={styles.cardDescr}>Bonus:</Text>
					<Text sx={styles.cardBody}>
						{item[intl.locale].bonus}
					</Text>
				</Box>
			</Box>
		))}

		<Heading as="h4" sx={styles.bonusCardsMarginTop}>MODULE CARDS</Heading>

		{data.module.map((item, index) => (
			<Box as="div" key={index} sx={styles.paragraph}>
				<Image alt="card" src={extra[item.image]} sx={styles.cardImage} />
				<Box as="div" sx={styles.rightCol}>
					<Text sx={{...styles.cardBody, mb: 10}}>
						{item[intl.locale].description}
					</Text>
					<Text as="div" sx={styles.cardDescr}><FormattedMessage id="weight" />:</Text>
					{item.weights.map((itemW, idx) => (
						<Text sx={styles.cardBody} key={idx}>
							{itemW.weight} =
							{itemW.modules.map((itemM, idxM) => (
								<Text key={idxM}>
									{itemM}
								</Text>
							))}
						</Text>
					))}					
				</Box>
			</Box>
		))}

		<Heading as="h4" sx={styles.bonusCardsMarginTop}>COACH CARDS</Heading>

		{data.coach.map((item, index) => (
			<Box as="div" key={index} sx={styles.paragraph}>
				<Image alt="card" src={extra[item.image]} sx={styles.cardImage} />
				<Box as="div" sx={styles.rightCol}>
					<Text as="div" sx={styles.cardDescr}><FormattedMessage id="condition" />:</Text>
					<Text sx={styles.cardBody}>
						{item[intl.locale].description}
					</Text>
				</Box>
			</Box>
		))}
	</Box>
	);
}

const styles = {
    description: {
        fontSize: 15
    },
	rightCol: {
		display: "flex",
		justifyContent: "center",
		flexDirection: "column",
	},
	cardDescr: {
		fontFamily: "Lato",
		fontWeight: 900,
		fontSize: "15px",
		letterSpacing: "1px",
		color: "#333",
	},
	cardName: {
		fontFamily: "Lato",
		fontWeight: 900,
		fontSize: "15px",
		letterSpacing: "1px",
		color: "#333",
	},
	cardBody: {
		fontFamily: "Lato",
		fontWeight: "normal",
		fontSize: "15px",
		color: "#333",
		"@media screen and (max-width: 1024px)": {
			lineHeight: "13px"
		}
	},
	cardImage: {
		width: 180,
		mr: 20,
		minWidth: 180
	},
	paragraph: {
		display: "flex",
		mb: 35,
		"@media screen and (max-width: 1024px)": {
			flexDirection: "column",
			alignItems: "center",
			mb: 40
		}
	},
	bonusCardsMarginTop: {
		fontFamily: "Lexend Zetta",
		fontStyle: "normal",
		fontWeight: 800,
		fontSize: "30px",
		lineHeight: "60px",
		letterSpacing: "-5px",
		color: "#000",
		mt: 100,
		mb: 35,
		"@media screen and (max-width: 1024px)": {
			display: "flex",
			justifyContent: "center",
			fontSize: "22px",
			mt: 62,
			mb: 30
		}
	},
	bonusCards: {
		fontFamily: "Lexend Zetta",
		fontStyle: "normal",
		fontWeight: 800,
		fontSize: "30px",
		lineHeight: "60px",
		letterSpacing: "-5px",
		color: "#000",
		mb: 35,
		"@media screen and (max-width: 1024px)": {
			display: "flex",
			justifyContent: "center",
			fontSize: "22px",
			mb: 25
		}
	},
	page: {
		pt: 170,
		mb: 100,
		"@media screen and (max-width: 1024px)": {
			pt: 111,
			px: 27,
		}
	},
	subtitle: {
		fontFamily: 'Barlow Condensed',
		fontStyle: "normal",
		fontWeight: 700,
		fontSize: "30px",
		lineHeight: "36px",
		textAlign: "center",
		letterSpacing: "0.15px",
		color: "#D4AF37",
		textTransform: "uppercase"
	},
	title: {
		fontFamily: 'Lexend Zetta',
		fontWeight: 800,
		fontSize: 60,
		textAlign: "center",
		letterSpacing: "-10.85px",
		color: "#000000",
		mb: 80,
		"@media screen and (max-width: 1024px)": {
			mb: 31,
			fontSize: 30,
			letterSpacing: -5
		}
	},
	text: {
		fontFamily: "Lato",
		fontWeight: 500,
		color: "#333",
		fontSize: 18,
		mb: 40
	},
	textTitle: {
		fontWeight: 900,
		mb: 0
	}
}