import React from "react";
import { Container, Box } from "theme-ui";
import { useIntl } from "gatsby-plugin-intl";
import { StickyProvider } from "contexts/app/app.provider";
import Seo from "components/seo";
import Layout from "components/layout";
import FooterImages from "../sections/footerImages/footerImages";
import { CAccordion } from '@coreui/react'
import "../styles/global.css";
import "../styles/how-to-play.css";
import '@coreui/coreui/dist/css/coreui.min.css';
import HowToPlay from "../components/accordions/howToPlay";
import WslCards from "../components/accordions/wslCards";
import LeaguesCups from "../components/accordions/leaguesCups";
import Packs from "../components/accordions/packs";

export default function IndexPage({ location }) {
    const intl = useIntl();

    const items = ['#how-to-play', '#wsl-cards', '#leagues-cups', '#farming', '#packs']
    
    const current = items.indexOf(location.hash);
    let activeItemKey = current >= 0 ? current + 1 : 1;
        
    return (
        <StickyProvider>
            <Layout scrollableLinks={false}>
                <Seo title="World Super League - How to play" />
                <Box as="div" style={{ backgroundColor: "#F5F5F5" }}>
                    <Container sx={styles.page}  className="how-to-play">
                        <Box sx={styles.banner}>
                            <img src={`/img/how-to-play/banner-${intl.locale}.png`} className="banner" alt="" />
                            <img src={`/img/how-to-play/banner-mobile-${intl.locale}.png`} className="banner mobile" alt="" />
                        </Box>
                        <CAccordion activeItemKey={activeItemKey}>
                            <HowToPlay />
                            <WslCards />
                            <LeaguesCups />
                            <Packs />
                        </CAccordion>
                    </Container>
                    <FooterImages />
                </Box>
            </Layout>
        </StickyProvider>
    );
}

const styles = {
    banner: {
        
    },
    rightCol: {
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
    },
    cardDescr: {
        fontFamily: "Lato",
        fontWeight: 900,
        fontSize: "15px",
        letterSpacing: "1px",
        color: "#333",
    },
    cardName: {
        fontFamily: "Lato",
        fontWeight: 900,
        fontSize: "15px",
        letterSpacing: "1px",
        color: "#333",
    },
    cardBody: {
        fontFamily: "Lato",
        fontWeight: "normal",
        fontSize: "15px",
        color: "#333",
        "@media screen and (max-width: 1024px)": {
            lineHeight: "13px"
        }
    },
    cardImage: {
        width: "20%",
        "@media screen and (max-width: 1024px)": {
            mb: 25,
        },
        "@media screen and (min-width: 1025px)": {
            mr: 44,
        }
    },
    paragraph: {
        display: "flex",
        mb: 35,
        "@media screen and (max-width: 1024px)": {
            flexDirection: "column",
            alignItems: "center",
            mb: 40
        }
    },
    bonusCardsMarginTop: {
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "30px",
        lineHeight: "60px",
        letterSpacing: "-5px",
        color: "#000",
        mt: 100,
        mb: 35,
        "@media screen and (max-width: 1024px)": {
            display: "flex",
            justifyContent: "center",
            fontSize: "22px",
            mt: 62,
            mb: 30
        }
    },
    bonusCards: {
        fontFamily: "Lexend Zetta",
        fontStyle: "normal",
        fontWeight: 800,
        fontSize: "30px",
        lineHeight: "60px",
        letterSpacing: "-5px",
        color: "#000",
        mb: 35,
        "@media screen and (max-width: 1024px)": {
            display: "flex",
            justifyContent: "center",
            fontSize: "22px",
            mb: 25
        }
    },
    page: {
        pt: 170,
        mb: 100,
        "@media screen and (max-width: 1024px)": {
            pt: 111,
            px: 27,
        }
    },
    subtitle: {
        fontFamily: 'Barlow Condensed',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "30px",
        lineHeight: "36px",
        textAlign: "center",
        letterSpacing: "0.15px",
        color: "#D4AF37",
        textTransform: "uppercase"
    },
    title: {
        fontFamily: 'Lexend Zetta',
        fontWeight: 800,
        fontSize: 60,
        textAlign: "center",
        letterSpacing: "-10.85px",
        color: "#000000",
        mb: 80,
        "@media screen and (max-width: 1024px)": {
            mb: 31,
            fontSize: 30,
            letterSpacing: -5
        }
    },
    text: {
        fontFamily: "Lato",
        fontWeight: 500,
        color: "#333",
        fontSize: 18,
        mb: 40
    },
    textTitle: {
        fontWeight: 900,
        mb: 0
    }
}