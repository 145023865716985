import React from "react";
import { Paragraph } from "theme-ui";
import { useIntl, FormattedMessage } from "gatsby-plugin-intl";
import { CAccordionItem, CAccordionHeader, CAccordionBody } from '@coreui/react'
import "../../styles/global.css";
import '@coreui/coreui/dist/css/coreui.min.css';

export default function LeaguesCups() {
    const intl = useIntl();
    return (
        <CAccordionItem itemKey={3}>
            <CAccordionHeader>3 <FormattedMessage id="leagues_and_cups" /></CAccordionHeader>
            <CAccordionBody>
                <Paragraph>
                    {intl.locale === "en" &&
                        <>
                            Cups are special competitions with amazing prizes, they may have different rules and criteria that must be met to join, for example the “WSL Cup” can be played by deploying only footballers who are playing in the World Championship.
                        </>
                    }

                    {intl.locale === "it" &&
                        <>
                            Le Coppe sono competizioni con premi speciali, ci possono essere diverse regole e condizioni da rispettare per poter giocare, ad esempio la “WSL CUP” potrà essere disputata solo schierando giocatori che partecipano al mondiale.
                        </>
                    }
                </Paragraph>
            </CAccordionBody>
        </CAccordionItem>
    );
}